/* fields for custom query configuration */

export const salesReportField_Parameters = [
    {name:'ProductName'},
    {name:'ProductCost'},
    {name:'ProductQuantity'},
    {name:'ProductPrinter'},
    {name:'TotalProductCost'},
    {name:'TotalPaymentProduct'},
    {name:'NameTVM'},
    {name:'idTransaction'},
    {name:'LocalId'},
    {name:'Total'},
    {name:'Coins'},
    {name:'Bills'},
    {name:'BillsCoins'},
    {name:'Credit'},
    {name:'QrIn'},
    {name:'QrOut'},
    {name:'Status'},
    {name:'Latitude'},
    {name:'Longitude'},
    {name:'RevenueId'},
    {name:'TVMNumber'},
    {name:'TVMVersion'},
    {name:'StationName'},
    {name:'DateYear'},
    {name:'DateYearMonth'},
    {name:'DateYearWeek'},
    {name:'Date'},
    {name:'Revenue'}
];

export const salesReportField_GroupBy = [
    {name:'ProductName_g'},
    {name:'NameTVM'},
    {name:'idTransaction'},
    {name:'LocalId'},
    {name:'Status'},
    {name:'Latitude'},
    {name:'Longitude'},
    {name:'RevenueId'},
    {name:'TVMNumber'},
    {name:'TVMVersion'},
    {name:'StationName_g'},
    {name:'DateYear_g'},
    {name:'DateYearMonth_g'},
    {name:'DateYearWeek_g'},
    {name:'Date'}
];

export const salesReportField_Filter =[
    /*{name:'ProductName'},*/
    {name:'Status'},
    {name:'TVMNumber'},
    {name:'NameTVM'},
    {name:'StationName'}/*,
    {name:'idStation'}*/
]

export const alarmsReportField_Parameters = [
    {name:'NameTVM'},
    {name:'TVMNumber'},
    {name:'TVMVersion'},
    {name:'StationName'},
    {name:'DateYear'},
    {name:'DateYearMonth'},
    {name:'DateYearWeek'},
    {name:'Date'},
    {name:'StatusMessage'},
    {name:'TotalRegister'},
    {name:'deviceName'},
    {name:'alarmType'},
    {name:'statusType'}
];

export const alarmsReportField_GroupBy = [
    {name:'StationName_g'},
    {name:'DateYear_g'},
    {name:'DateYearMonth_g'},
    {name:'DateYearWeek_g'}
];

export const alarmsReportField_Filter = [
    {name:'deviceName'},
    {name:'statusType'},
    {name:'alarmType'},
    {name:'TVMNumber'},
    {name:'NameTVM'},
    {name:'StationName'}/*,
    {name:'idStation'}*/
];

export const changesReportField_Parameters = [
    {name:'QRCode'},
    {name:'Source'},
    {name:'LocalId'},
    {name:'Active'},
    {name:'IssuedDate'},
    {name:'UsedDate'},
    {name:'ExpirationDate'},
    {name:'TypeQRCode'},
    {name:'TVMNameCreateBy'},
    {name:'TVMNumberCreateBy'},
    {name:'TVMNameUsedBy'},
    {name:'TVMNumberUsedBy'},
    {name:'TotalRegister'}
];

export const changesReportField_GroupBy = [
    {name:'LocalId_g'},
    {name:'StationName_g'}
];

export const changesReportField_Filter = [
    {name:'Source'},
    {name:'StationName'},
    {name:'Active'},
    {name:'DisabledBy'}
];

export const reportType = {
    Sales:'Sales',
    Alarms:'Alarms',
    Changes:'Changes'
}




import GeneralTable from "../../../Hooks/Table/GeneralTable";
import getPermission from "../../../auth/GetPermission";
import AddButton from "../../../Common/AddButton";
import getAllCommands from "../../../api/Commands/getAllCommands";
import CommandsNewModal from "./CommandsNewModal";
import CommandsEditModal from "./CommandsEditModal";
import deleteCommand from "../../../api/Commands/deleteCommand";

import { Box } from '@mui/material';

import { useState, useEffect } from "react";

import { Spinner } from "react-bootstrap";
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';

import './css/CommandsPage.css'
import handleDateChange from "../../../Common/HandleDateChange";
import handleTimeChange from "../../../Common/HandleTimeChange";
import getHour from "../../../Common/GetHour";
import getDate from "../../../Common/GetDate";
import formatLocalDateTime from "../../../Common/FormatLocalDateTime";
import Footer from "../../../Common/Footer";

function Commands() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingCommandId, setEditingCommandId] = useState([]);
    const [commands, setCommands] = useState([]);
    const [startDate, setStartDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(0, 0, 0, 0);
        return initialDate;
    });
    const [endDate, setEndDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(23, 59, 59, 999);
        return initialDate;
    });
    const [filteredCommands, setFilteredCommands] = useState(data);
    const language = JSON.parse(localStorage.getItem('language'));
    const [unfilteredData, setUnfilteredData] = useState([]);
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];    
    const [datesError, setDatesError] = useState('');
    const currentDate = new Date();

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData(formatDate(new Date()), formatDate(new Date()));
                setCommands(response.getCommandsName);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    async function getData(fromDate, toDate) {
        const {data,error} = await getAllCommands(currentTenant, fromDate, toDate);

        if(error !== undefined)
        {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const columns = [
        {
            header: language.Commands,
            accessorKey: 'command',
            muiTableBodyCellProps: {
                align: 'left',
              },
        },
        {
            header: language.Parameters,
            accessorKey: 'params',
            muiTableBodyCellProps: {
                align: 'left',
              },
        },
        {
            header: language.UploadDate,
            accessorKey: 'uploadDate',
            Cell: ({ renderedCellValue }) => (
                <div>{new Date(renderedCellValue).toLocaleString(languageCode)}</div>
            ),
            muiTableBodyCellProps: {
                align: 'right',
              },
        },
        {
            header: language.Active,
            accessorKey: 'isActive',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'} 
                    className='table__checkbox'/>
                </Box>
        )
        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idCommandsDevice)}
                disabled={!getPermission(currentUser, "DMConfigCommands", "updateAllow")}>
                        <img src='./img/icons/pages/EditUser.svg' className='table__icon'/>
            </button> 
        </Box>
    );

    const handleEditClick = (id) => {
        setEditingCommandId(id);
        setShowEditModal(true);
    };

    const handleFilter = async () => {
        setIsLoading(true);

        if(startDate <= endDate) {
            const newData = await getData(startDate.toISOString(), endDate.toISOString());
            setData(newData.data);
            setDatesError('');
        } else {
            setDatesError(language.DatesError)
        }
        setIsLoading(false);
    };

    const handleClearFilter = async () => {
        setIsLoading(true);
        setData([]);
        setDatesError('');
        setIsLoading(false);
    };

    const formatEndTime = (date) => {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay;
    };

    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    return(
        <div className='main-container-app'>
                <h1 className='module__title'>{language.Commands}</h1>

                <div className='inputs-row__no-justified'>
                    <div className="input-name commands-date-input">
                        <label className='input__label'>{language.StartDate}:</label>
                        <input
                            type="date"
                            value={startDate ? getDate(new Date(startDate)) : ''}
                            onChange={event => handleDateChange(event, startDate, setStartDate)}
                            className='module__input-white'
                        />
                        <input
                            type="time"
                            value={startDate ? getHour(new Date(startDate)) : '00:00'}
                            onChange={event => handleTimeChange(event, startDate, setStartDate)}
                            className='module__input-white'
                            style={{marginTop: '0.5rem'}}
                        />
                    </div>
                    <div className="input-name commands-date-input">
                        <label className='input__label'>{language.EndDate}:</label>
                        <input
                            type="date"
                            value={endDate ? getDate(new Date(endDate)) : ''}
                            onChange={event => handleDateChange(event, endDate, setEndDate)}
                            className='module__input-white'
                        />
                        <input
                            type="time"
                            value={endDate ? getHour(new Date(endDate)) : '00:00'}
                            onChange={event => handleTimeChange(event, endDate, setEndDate)}
                            className='module__input-white'
                            style={{marginTop: '0.5rem'}}
                        />
                    </div>
                    <Button className='general-button primary date__button' onClick={handleFilter} >
                        {language.Filter}
                    </Button>
                    <Button className='general-button danger date__button' onClick={handleClearFilter} >
                        {language.ClearFilter}
                    </Button>
                </div>
                {datesError &&
                    <div className="alert-message">{datesError}</div>
                }

                {getPermission(currentUser, "DMConfigCommands", "readAllow") && 
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions}/>
                        {
                            getPermission(currentUser, "DMConfigCommands", "writeAllow") &&
                            <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
                        }
                        
                    <CommandsNewModal 
                        show={showNewModal} 
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}
                        commandsNames={commands}
                        startDate={startDate.toISOString()}
                        endDate={endDate.toISOString()}
                        />
                    <CommandsEditModal
                        show={showEditModal} 
                        onHide={() => setShowEditModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}
                        commandsNames={commands}
                        editingCommandId={editingCommandId} 
                        startDate={startDate.toISOString()}
                        endDate={endDate.toISOString()}
                        />
                </>
                }
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner'/>
                </div>
            }
            <Footer/>
        </div>
    );
}

export default Commands;